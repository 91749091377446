// Components
import VuetifyToolBar from '@/components/ui/VuetifyToolBar'
import VuetifyContentLoading from '@/components/ui/VuetifyContentLoading'
// Services
import { acceptRelationship } from '@/services/relationships'
// Vuex
import { mapActions, mapGetters } from 'vuex'
// Utils
import { isNil } from 'lodash'

export default {
  name: 'AcceptRelationships',
  components: {
    VuetifyToolBar,
    VuetifyContentLoading
  },
  data() {
    return {
      id: this.$route.params.id || null, // id de relación
      resultText: 'Espere por favor...',
      processingRequest: true,
      iconName: 'mdi-check-circle',
      iconColor: 'green darken-2'
    }
  },
  computed: {
    ...mapGetters('company', ['companyData'])
  },
  mounted() {
    this.getEveryNeededData()
  },
  methods: {
    ...mapActions('company', ['getCompany']),
    /**
     * Obtiene/establece los datos necesarios para el componente
     */
    async getEveryNeededData() {
      await this.setAcceptRelationships()
    },
    /**
     * Realizamos el proceso de relación entre las compañías
     */
    async setAcceptRelationships() {
      try {
        // loading
        this.processingRequest = true
        // No tenemos identidicador de proceso
        if (isNil(this.id)) {
          throw new Error('No existe identificador de proceso.')
        }

        // Lanzamos petición
        const result = await acceptRelationship(this.id)

        if (!result) {
          throw new Error('No se pudo completar el proceso de relación correctamente.')
        }

        // Actualizamos datos de la compañía en Store
        await this.getCompany(this.companyData.id)

        // Texto de resultado
        this.resultText = 'El proceso de relación acabó correctamente.'
      } catch (error) {
        // Mensajes de error
        this.resultText = error.message
        // Iconos y color
        this.iconName = 'mdi-block-helper'
        this.iconColor = 'orange darken-2'
      } finally {
        // loading
        this.processingRequest = false
      }
    }
  }
}
