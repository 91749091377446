<template>
  <v-toolbar
    :class="{ 'vuetify-toolbar': true, shrink: true, sticky }"
    v-bind="customToolBarVOptions"
  >
    <!-- Back buton -->
    <v-btn
      v-if="!hideBackButton"
      icon
      data-cy="bar-back-button"
      @click="handleClickToolBarBackButton"
    >
      <v-icon>mdi-chevron-left</v-icon>
    </v-btn>
    <!-- Title -->
    <v-toolbar-title v-if="!hideTitle" color="primary">
      {{ title }}
    </v-toolbar-title>
    <!-- Action button -->
    <v-spacer></v-spacer>
    <slot name="actionButton">
      <v-btn
        v-if="!hideActionButton"
        data-cy="bar-add-action-button"
        icon
        v-bind="customButtonVOptions"
        class="text-none"
        @click="handleClickToolBarActionButton"
      >
        <slot name="diplayButton">
          <v-icon>mdi-plus</v-icon>
        </slot>
      </v-btn>
    </slot>
  </v-toolbar>
</template>

<script>
// Mixins
import uiMixin from '@/mixins/uiMixin'

export default {
  name: 'VuetifyToolBar',
  mixins: [uiMixin],
  props: {
    title: {
      default: 'Title',
      type: String
    },
    hideTitle: {
      default: false,
      type: Boolean
    },
    hideBackButton: {
      default: false,
      type: Boolean
    },
    hideActionButton: {
      default: true,
      type: Boolean
    },
    buttonVOptions: {
      default() {
        return {}
      },
      type: Object
    },
    sticky: {
      default: false,
      type: Boolean
    },
    /**
     * https://vuetifyjs.com/en/components/toolbars/#toolbars
     */
    toolBarVOptions: {
      default() {
        return {
          dense: true,
          flat: true
        }
      },
      type: Object
    }
  },
  computed: {
    /**
     * Estilos del botón personalizados
     *
     * @return {Object}
     */
    customButtonVOptions() {
      return { icon: true, elevation: 0, ...this.buttonVOptions }
    },
    /**
     * Estilos de la barra personalizado
     *
     * @return {Object}
     */
    customToolBarVOptions() {
      return { ...this.toolBarVOptions }
    }
  },
  methods: {
    /**
     * Handle Back Button
     */
    handleClickToolBarBackButton() {
      this.routerGoTo(-1)
    },
    /**
     * Handle Action Button
     */
    handleClickToolBarActionButton() {
      this.$emit('onClickToolBarActionButton')
    }
  }
}
</script>

<style lang="scss" scoped>
.vuetify-toolbar {
  background-color: $theme_background !important;
  .v-toolbar__content {
    & > .v-btn.v-btn--icon:first-child {
      & + .v-toolbar__title {
        padding-left: 0;
        font-size: 1rem;
        line-height: 1.25rem;
      }
    }
    .v-toolbar__title {
      font-size: 1rem;
      line-height: 1.25rem;
    }
  }
  &.sticky {
    border-bottom: 1px solid $theme_border_color;
    z-index: 1;
    position: sticky;
    top: 48px;
    left: 0;
  }
}
@media (max-width: 600px) {
  .vuetify-toolbar {
    .v-toolbar__content {
      & > .v-btn.v-btn--icon:first-child {
        & + .v-toolbar__title {
          font-size: 1rem;
          line-height: 1.25rem;
        }
      }
      .v-toolbar__title {
        font-size: 1rem;
        line-height: 1.25rem;
      }
    }
  }
}
</style>
